<template>
  <div id="app">
    <section id="portrait">
      Please rotate your device to landscape to view the site content
    </section>
    <section id="password" v-if="!passwordEntered">
      <div class="password-box">
        <h2>Enter password</h2>
        <input v-model="password" type="password" autocomplete="off" @change="passwordChanged">
      </div>
    </section>
    <header>
      <div class="head-wrap">
        <div class="container">
          <div class="flex flex-row full-width space-between">
            <div class="left">
              <div class="flex flex-row flex-align-center">
                <img class="menu" src="./assets/images/burger@2x.png" width="40" height="30" @click="openMenu">
                <h1>
                  Help your patients to take a targeted next step
                </h1>
              </div>
            </div>
            <div class="right">
              <router-link class="logo-link" :to="{name : 'welcome'}">
                <img src="./assets/images/drug-logos@2x.png" width="219" alt="">
              </router-link>
            </div>
          </div>

        </div>
      </div>

      <section id="pi-area">
        <div class="container">
           <span class="pi-button" @click="openPI">
              Prescribing Information
           </span>
          <span class="ae-button" @click="openAE">
            Adverse Event Reporting
          </span>
        </div>
      </section>
    </header>
    <main>
      <div class="container" v-show="piOpen">
        <Modal class="pi-modal" ref="pimodal" v-on:modalopened="piOpen = true" v-on:modalclosed="piOpen = false">
          <div class="pi-content">
            <img src="~@/assets/images/pi@2x.png" width="978" class="centered" alt="PI">
          </div>
        </Modal>
      </div>
      <div class="container ae-container" v-show="aeOpen">
        <Modal class="ae-modal" ref="aemodal" v-on:modalopened="aeOpen = true" v-on:modalclosed="aeOpen = false">
          <div class="ae-content">
            <div class="ae-box">
              <p>&#x25bc;Encorafenib and &#x25bc; binimetinib are subject to additional monitoring. This will allow for
                quick identification of new safety information.</p>

              <p>Adverse events should be reported. In the UK, reporting forms and information can be found at the
                Yellow Card Scheme Website: <a target="_blank" href="http://www.mhra.gov.uk/yellowcard">www.mhra.gov.uk/yellowcard</a> or search for MHRA Yellow Card in the Google Play
                or Apple App Store. In the Republic of Ireland adverse events should be reported to the HPRA. Reporting
                information for the HPRA can be found at: <a target="_blank" href="https://www.hpra.ie">www.hpra.ie</a>.</p>

              <p>Adverse events should also be reported to Pierre Fabre Ltd. Telephone: United Kingdom <strong>0800 085
                5292</strong>,
                <br>
                or by email: <a href="mailto:uk_mi@pierre-fabre.com">uk_mi@pierre-fabre.com</a>. Republic of Ireland 1800 812 464, or by email:
                <a href="mailto:ie_mi@pierre-fabre.com">ie_mi@pierre-fabre.com</a></p>
            </div>
          </div>
        </Modal>
      </div>
      <nav v-show="menuOpen">
        <Modal class="main-menu" ref="menumodal" no-auto-height opened-by-default transparent v-on:modalclosed="onMenuClosed">
          <ul>
            <li @click="goToSectionIndex(0)">COLUMBUS 5-Year Data Summary</li>
            <li @click="goToSectionIndex(1)">Study Design</li>
            <li @click="goToSectionIndex(2)">Progression-Free Survival (PFS)</li>
            <li @click="goToSectionIndex(3)">Overall Survival (OS)</li>
            <li @click="goToSectionIndex(4)">Response Rates</li>
            <li @click="goToSectionIndex(5)">Safety</li>
            <li @click="openPI">Prescribing Information</li>
            <li @click="openAE">Adverse Event Reporting</li>
          </ul>
        </Modal>
      </nav>
      <router-view v-show="!piOpen && !menuOpen && !aeOpen"/>

    </main>
    <footer v-if="showSmallFooter === false">
      <div class="container">
        <div class="bottom-row flex flex-row full-width space-between">
          <div class="left">
            <p style="margin-top: 0" class="promo">
              This promotional presentation has been created and funded by Pierre Fabre and is intended for healthcare professionals only.
            </p>

            <div class="ae-box">
              <p>&#x25bc;Encorafenib and &#x25bc; binimetinib are subject to additional monitoring. This will allow for
                quick identification of new safety information.</p>

              <p>Adverse events should be reported. In the UK, reporting forms and information can be found at the
                Yellow Card Scheme Website: <a target="_blank" href="http://www.mhra.gov.uk/yellowcard">www.mhra.gov.uk/yellowcard</a> or search for MHRA Yellow Card in the Google Play
                or Apple App Store. In the Republic of Ireland adverse events should be reported to the HPRA. Reporting
                information for the HPRA can be found at: <a target="_blank" href="https://www.hpra.ie">www.hpra.ie</a>.</p>

              <p>Adverse events should also be reported to Pierre Fabre Ltd. Telephone: United Kingdom <strong>0800 085
                5292</strong>,
                <br>
                or by email: <a href="mailto:uk_mi@pierre-fabre.com">uk_mi@pierre-fabre.com</a>. Republic of Ireland 1800 812 464, or by email:
                <a href="mailto:ie_mi@pierre-fabre.com">ie_mi@pierre-fabre.com</a></p>
            </div>
          </div>
          <div class="right">
            <img src="./assets/images/pf-logo@2x.png" width="116" alt="">
            <div class="disclaimer">
              <p>
                &copy; 2021 Pierre Fabre. All rights reserved. BRAFTOVI<sup>&reg;</sup> and MEKTOVI<sup>&reg;</sup> are
                <br> distributed by Array BioPharma Inc., a wholly owned subsidiary of Pfizer Inc.
              </p>
            </div>
            <div class="job-code">
              UK/ENC/0524 October 2021
            </div>
          </div>
        </div>
      </div>
    </footer>
    <footer class="small-footer" v-if="showSmallFooter">
      <div class="container">
        <div class="bottom-row flex flex-row full-width space-between">
          <div class="left">
            <p>
              This promotional presentation has been created and funded by Pierre Fabre and is intended for healthcare professionals only.
            </p>
            <p>
              UK/ENC/0524 October 2021
            </p>
          </div>
          <div class="right">
            <img src="./assets/images/pf-logo@2x.png" width="116" alt="">
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>
<script>
import Modal from "./components/Modal";
export default {
  name: "App",
  components: {Modal},
  data: function () {
    return{
      piOpen: false,
      menuOpen: false,
      aeOpen: false,
      passwordEntered: false,
      password : null,
      showSmallFooter : false
    }
  },
  watch:{
    $route (route){
      this.switchFooterSize(route);
    }
  },
  mounted: function() {
    this.switchFooterSize(this.$route)
  },
  methods: {
    switchFooterSize: function (route) {
      this.showSmallFooter = route.name === "home";
    },
    openPI: function () {
      this.menuOpen = false;
      this.piOpen = true;
      this.aeOpen = false;
      this.$refs.pimodal.openModal();
    },
    openAE: function () {
      this.menuOpen = false;
      this.piOpen = false;
      this.aeOpen = true;
      this.$refs.aemodal.openModal();
    },
    openMenu: function (){
      this.$refs.menumodal.openModal();
      this.menuOpen = true;
      this.piOpen = false;
      this.aeOpen = false;
    },
    onMenuClosed: function () {
      this.menuOpen = false;
    },
    goToSectionIndex: function (tabIndex) {
      this.menuOpen = false;
      this.$router.push({name : "home", params :{defaultTabIndex : tabIndex}}).catch(() => { /* ignore */ })
    },
    passwordChanged: function () {

      if(this.password === "pf2021"){
        this.passwordEntered = true;
      }
    }
  }
}
</script>