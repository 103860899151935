<template>
    <section class="main-area">
      <div class="container" >
        <div class="hotspots" v-show="!isMainContentOpen">
          <div class="hotspot-row top">
            <div class="hotspot five-year-data" @click="selectHotspot(0)">
              <div class="animation"></div>
              <div class="text">COLUMBUS 5-Year <br>
                Data Summary</div>
            </div>
          </div>

          <div class="hotspot-row middle">
            <div class="hotspot study-design" @click="selectHotspot(1)">
              <div class="animation"></div>
              <div class="text">Study Design</div>
            </div>
            <div class="hotspot pfs" @click="selectHotspot(2)">
              <div class="animation"></div>
              <div class="text">Progression-Free Survival (PFS)</div>
            </div>
            <div class="hotspot overall-survival" @click="selectHotspot(3)">
              <div class="animation"></div>
              <div class="text">Overall Survival (OS)</div>
            </div>
          </div>

          <div class="hotspot-row bottom">
            <div class="hotspot empty"></div>
            <div class="hotspot response-rates" @click="selectHotspot(4)">
              <div class="animation"></div>
              <div class="text">Response Rates</div>
            </div>
            <div class="hotspot safety" @click="selectHotspot(5)">
              <div class="animation"></div>
              <div class="text">Safety</div>
            </div>
          </div>

        </div>
        <Modal ref="maincontent" v-show="isMainContentOpen" v-on:modalclosed="onContentClosed">
          <Tabs ref="maintabs" v-on:tabupdated="tabUpdated" class="main-tabs">
            <Tab title="COLUMBUS 5-Year Data Summary">
              <h2>
                COLUMBUS 5-Year Data Summary
              </h2>
              <ul>
                <li>
                  In the open-label, phase 3 COLUMBUS study, <span class="highlight">BRAFTOVI<sup>&reg;</sup> (encorafenib) + MEKTOVI<sup>®</sup> (binimetinib)</span> produced a clinically significant increase in progression-free and overall survival vs vemurafenib, while demonstrating an acceptable safety profile.<sup>1,2</sup>
                </li>
                <li>
                  At ASCO 2021, a 5-year update from Part 1 of the COLUMBUS trial was reported.<sup>3</sup>
                </li>
                <li>
                  These 5-year data confirm the long-term efficacy of <span class="highlight">BRAFTOVI + MEKTOVI</span>:<sup>3</sup>
                </li>
              </ul>
              <div class="boxes">
                <div class="box">
                  <div class="top">Median PFS</div>
                  <div class="middle">14.9 months with <br> <span class="smaller">BRAFTOVI + MEKTOVI</span> </div>
                  <div class="bottom">vs 7.3 months with vemurafenib</div>
                </div>
                <div class="box">
                  <div class="top">Median OS</div>
                  <div class="middle">33.6 months with <br> <span class="smaller">BRAFTOVI + MEKTOVI</span></div>
                  <div class="bottom">vs 16.9 months with vemurafenib</div>
                </div>
                <div class="box">
                  <div class="top">Overall response rate</div>
                  <div class="middle">64% of patients with <br> <span class="smaller">BRAFTOVI + MEKTOVI</span></div>
                  <div class="bottom">vs 41% with vemurafenib</div>
                </div>
              </div>

              <ul>
                <li>
                  The safety profile over 5 years was consistent with previous observations of <span class="highlight">BRAFTOVI + MEKTOVI.</span><sup>3</sup>
                </li>
              </ul>

              <div class="references top-border">
                CR, complete response; OS, overall survival; PFS, progression-free survival; PR, partial response. <br>
                1. Dummer R, et al. <em>Lancet Oncol</em>. 2018;19:603–15. 2. Dummer R, et al. <em>Lancet Oncol</em>. 2018;19:1315–27. 3. Pierre Fabre, Data on File: 01-2021-UK.
              </div>

              <div class="foot-bar">
                Would you like to receive promotional and non-promotional information directly from Pierre Fabre Ltd?
                <span>
                  If so, please discuss this with a Pierre Fabre representative who can assist you in providing your consent.
                </span>
              </div>

            </Tab>
            <Tab title="Study Design">

              <h2>
                COLUMBUS Study Design
              </h2>

              <div class="flex flex-row study-design-first">
                <div class="left">
                  <p>
                    COLUMBUS is a two-part, multicentre, randomised, open-label, phase 3 study designed to evaluate the safety and efficacy of
                    <span class="highlight">BRAFTOVI<sup>&reg;</sup> (encorafenib) + MEKTOVI<sup>&reg;</sup> (binimetinib)</span> in patients with
                    <em>BRAF</em> V600-mutant melanoma.<sup>1,2</sup>

                  </p>
                  <p>
                    The COLUMBUS trial was designed and started before other targeted therapy combinations were approved.<sup>3&#8211;5</sup>
                  </p>

                  <p>
                    <strong>Inclusion criteria:</strong>
                  </p>

                  <ul>
                    <li>Locally advanced unresectable or metastatic melanoma</li>
                    <li>Untreated or progressed on/after one prior 1L immunotherapy</li>
                    <li><em>BRAF</em> V600E and/or V600K mutant melanoma</li>
                    <li>ECOG PS 0 or 1</li>
                  </ul>

                </div>
                <div class="right">
                  <img src="~@/assets/images/study-design-img1@2x.png" width="456" alt="">

                  <p class="foot-note text-left">
                    *Randomisation was stratified by AJCC stage (IIIB, IIIC, IVM1a, IVM1b, or IVM1c), ECOG performance status (0 or 1), and previous first-line immunotherapy (y/n). Enrollment occurred from December 30, 2013 to April 10, 2015.
                    <br> Data cut-off: September 15, 2020. Minimum follow-up: 65 months. The study is ongoing.
                  </p>


                </div>
              </div>

              <h2>
                COLUMBUS Study Endpoints
              </h2>
              <p>
                In the main COLUMBUS study, <span class="highlight">BRAFTOVI + MEKTOVI</span> produced a clinically significant increase in progression-free and overall survival vs vemurafenib, while demonstrating an acceptable safety profile.<sup>1,2</sup>
              </p>
              <p style="margin-bottom: 40px;">
                At ASCO 2021, a 5-year update from Part 1 of the COLUMBUS trial was reported.<sup>6</sup>
              </p>
              <div class="study-boxes">
                <div class="study-box first">
                  <span>Primary Endpoint<sup>6</sup></span>
                  <ul>
                    <li>
                      Progression-free survival (PFS) by BICR
                    </li>
                  </ul>
                </div>
                <div class="study-box second">
                  <span>Selected Secondary Endpoints<sup>6</sup></span>
                  <div class="flex-row flex">
                    <div class="left">
                      <ul>
                        <li>Overall survival (OS)</li>
                        <li>Safety</li>
                      </ul>
                    </div>
                    <div class="right">
                      <ul>
                        <li>Best overall response</li>
                        <li>Additional secondary endpoints have not yet been reported at 5 years</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <h2>
                COLUMBUS Baseline Characteristics
              </h2>

              <p style="margin-bottom: 40px;">
                Baseline characteristics were similar between <span class="highlight">BRAFTOVI + MEKTOVI</span> and vemurafenib study arms.<sup>6</sup>
              </p>

              <span class="popup-title" @click="openOverlay('study_design_table')">
                COLUMBUS Baseline Characteristics<sup>6</sup>
              </span>

              <img class="centered" src="~@/assets/images/study-design-table-1@2x.png" width="755" alt="">

              <FullScreenOverlay ref="study_design_table">
                <div class="title">
                  <p class="text-center">
                    For full accompanying text, please close this pop-up.
                  </p>
                </div>

                <div class="content">
                  <h3 class="text-center">
                    COLUMBUS Baseline Characteristics<sup>1</sup>
                  </h3>

                  <img class="centered" src="~@/assets/images/study-design-table-1-overlay@2x.png" width="819" alt="">

                  <p class="foot-note text-left">
                    1. Pierre Fabre, Data on File: 01-2021-UK.
                  </p>
                </div>


              </FullScreenOverlay>

              <div class="references top-border">
                <p style="margin-top: 0px">1L, first-line; AJCC, American Joint Committee on Cancer; BICR, blinded independent central review;
                  ECOG PS, Eastern Cooperative Oncology Group performance status; LDH, lactate dehydrogenase; OS, overall
                  survival; PFS, progression-free survival; ULN, upper limit of normal; y/n, yes/no.</p>
                <p>
                  1. Dummer R, et al. <em>Lancet Oncol</em>. 2018;19:603–15. 2. Dummer R, et al. <em>Lancet Oncol</em>. 2018;19:1315–27. 3. ClinicalTrials.gov. NCT01909453. Available at: https://clinicaltrials.gov/ct2/show/NCT01909453. Accessed October 2021. 4. Mekinist (trametinib) summary of product characteristics. 5. Tafinlar (dabrafenib) summary of product characteristics. 6. Pierre Fabre, Data on File: 01-2021-UK.
                </p>
              </div>

            </Tab>
            <Tab title="Progression-Free Survival (PFS)">
              <h2>
                5-Year PFS – All Patients<sup>1</sup>
              </h2>

              <p>
                The PFS for patients at 5 years confirms the long-term efficacy of <span class="highlight">BRAFTOVI<sup>&reg;</sup>
                (encorafenib) + MEKTOVI<sup>&reg;</sup> (binimetinib)</span>.
              </p>

              <div class="pfs-info">
                <img src="~@/assets/images/one-in-five@2x.png" width="180" alt="">
                <p>
                  <span class="more-than">More than 1 in 5 </span> <span><strong style="font-weight: 700;">patients were progression-free at 5 years with</strong></span> <span class="highlight">BRAFTOVI + MEKTOVI</span>
                </p>
              </div>


              <span class="popup-title" @click="openOverlay('pfs_graph_1')">
                5-Year Progression-Free Survival – All Patients<sup>1</sup>
              </span>

              <img src="~@/assets/images/pfs-graph1@2x.png" class="centered" style="margin-top: 40px;" width="789" alt="">

              <FullScreenOverlay ref="pfs_graph_1">

                <div class="title">
                  <p class="text-center">
                    For full accompanying text, please close this pop-up.
                  </p>
                </div>

                <div class="content">
                  <h3 class="text-center">
                    5-Year Progression-Free Survival – All Patients<sup>1</sup>
                  </h3>

                  <img src="~@/assets/images/pfs-graph1-overlay@2x.png" class="centered" style="" width="858" alt="">

                  <p class="foot-note text-left">
                    1. Pierre Fabre, Data on File: 01-2021-UK.
                  </p>
                </div>


              </FullScreenOverlay>

              <h2>
                5-Year PFS – By Baseline LDH<sup>1</sup>
              </h2>

              <p>
                LDH levels could be a marker of tumour burden for advanced cancer patients, with higher LDH levels associated with shorter survival in various types of cancer.<sup>2</sup> PFS was assessed by LDH subgroup in patients receiving
                <span class="highlight">BRAFTOVI + MEKTOVI</span>: normal LDH (≤ULN) or high LDH (>ULN).
              </p>

              <img class="centered pfs-months" src="~@/assets/images/pfs-months@2x.png" width="563" alt="">

              <p class="foot-note" style="margin-bottom: 40px;">
                *The high LDH PFS results are consistent with the known poor prognosis of these patients.<sup>2</sup> Caution is advised in the interpretation of small sample subgroups.
              </p>


              <span class="popup-title" @click="openOverlay('pfs_graph_2')">
                5-Year Progression-Free Survival – By Baseline LDH<sup>1</sup>
              </span>

              <img class="centered" src="~@/assets/images/pfs-graph2@2x.png" style="margin-top: 40px;" width="790" alt="">

              <FullScreenOverlay ref="pfs_graph_2">

                <div class="title">
                  <p class="text-center">
                    For full accompanying text, please close this pop-up.
                  </p>
                </div>

                <div class="content">
                  <h3 class="text-center">
                    5-Year Progression-Free Survival – By Baseline LDH<sup>1</sup>
                  </h3>

                  <img class="centered" src="~@/assets/images/pfs-graph2-overlay@2x.png" style="margin-top: 40px;" width="880" alt="">

                  <p class="text-left foot-note">
                    *The high LDH PFS results are consistent with the known poor prognosis of these patients.<sup>2</sup> Caution is advised in the interpretation of small sample subgroups.

                  </p>

                  <p class="text-left foot-note">
                    1. Pierre Fabre, Data on File: 01-2021-UK; 2. Agarwala SS, et al. <em>Eur J Cancer</em>. 2009;45:1807–14.
                  </p>
                </div>



              </FullScreenOverlay>


              <p class="foot-note">
                *The high LDH PFS results are consistent with the known poor prognosis of these patients.<sup>2</sup> Caution is advised in the interpretation of small sample subgroups.
              </p>

              <div class="references top-border">
                CI, confidence interval; HR, hazard ratio; LDH, lactate dehydrogenase; NE, not evaluable; PFS, progression-free survival; ULN, upper limit of normal.
                <br>
                1. Pierre Fabre, Data on File: 01-2021-UK. 2. Agarwala SS, et al. <em>Eur J Cancer</em>. 2009;45:1807–14.
              </div>

            </Tab>
            <Tab title="Overall Survival (OS)">
              <h2>
                5-Year OS – All Patients<sup>1</sup>
              </h2>
              <p>
                In the COLUMBUS study, patients lived for longer when treated with <span class="highlight">BRAFTOVI<sup>&reg;</sup>
                (encorafenib) + MEKTOVI<sup>&reg;</sup> (binimetinib)</span> vs vemurafenib.
              </p>


              <div class="os-info">
                <img src="~@/assets/images/one-in-three@2x.png" width="106" alt="">

                <p>
                  <span class="more-than">More than 1 in 3 </span> <span>patients treated with</span> <span class="highlight">BRAFTOVI + MEKTOVI</span> were still alive at 5 years
                </p>
              </div>


              <span style="margin-top: 40px;" class="popup-title" @click="openOverlay('os_graph_1')">
                5-Year Overall Survival – All Patients<sup>1</sup>
              </span>

              <img src="~@/assets/images/os-graph1@2x.png" class="centered" width="875" style="margin-bottom: 0" alt="">

              <FullScreenOverlay ref="os_graph_1">

                <div class="title">
                  <p class="text-center">
                    For full accompanying text, please close this pop-up.
                  </p>
                </div>

                <div class="content">
                  <h3 class="text-center">
                    5-Year Overall Survival – All Patients<sup>1</sup>
                  </h3>

                  <img src="~@/assets/images/os-graph1-overlay@2x.png" class="centered" width="891" style="margin-bottom: 0" alt="">

                  <p class="foot-note text-left">
                    1. Pierre Fabre, Data on File: 01-2021-UK.
                  </p>
                </div>


              </FullScreenOverlay>

              <h2>
                5-Year OS – By Baseline LDH<sup>1</sup>
              </h2>

              <p>
                LDH levels could be a marker of tumour burden for advanced cancer patients, with higher LDH levels associated with shorter survival in various types of cancer.<sup>2</sup> OS was assessed by LDH subgroup in patients receiving
                <span class="highlight">BRAFTOVI + MEKTOVI</span>: normal LDH (≤ULN) or high LDH (>ULN).

              </p>

              <img class="centered" src="~@/assets/images/os-months@2x.png" width="563" alt="">

              <p class="foot-note" style="margin-bottom: 40px;">
                *The high LDH OS results are consistent with the known poor prognosis of these patients.<sup>2</sup> Caution is advised in the interpretation of small sample subgroups.
              </p>

              <span class="popup-title" @click="openOverlay('os_graph_2')">
                5-Year Overall Survival – By Baseline LDH<sup>1</sup>
              </span>

              <img class="centered" src="~@/assets/images/os-graph2@2x.png" style="margin-top: 40px;" width="889" alt="">

              <FullScreenOverlay ref="os_graph_2">
                <div class="title">
                  <p class="text-center">
                    For full accompanying text, please close this pop-up.
                  </p>
                </div>

                <div class="content">
                  <h3 class="text-center">
                    5-Year Overall Survival – By Baseline LDH<sup>1</sup>
                  </h3>

                  <img class="centered" src="~@/assets/images/os-graph2-overlay@2x.png" style="margin-top: 40px;" width="914" alt="">


                  <p class="foot-note text-left">
                    *The high LDH OS results are consistent with the known poor prognosis of these patients.<sup>2</sup> Caution is advised in the interpretation of small sample subgroups.
                  </p>

                  <p class="foot-note text-left">
                    1. Pierre Fabre, Data on File: 01-2021-UK; 2. Agarwala SS, et al. <em>Eur J Cancer</em>. 2009;45:1807–14.
                  </p>
                </div>



              </FullScreenOverlay>

              <p class="foot-note">
                *The high LDH OS results are consistent with the known poor prognosis of these patients.<sup>2</sup> Caution is advised in the interpretation of small sample subgroups.
              </p>

              <div class="references top-border">
                CI, confidence interval; HR, hazard ratio; LDH, lactate dehydrogenase; OS, overall survival; ULN, upper limit of normal.
                <br>
                1. Pierre Fabre, Data on File: 01-2021-UK. 2. Agarwala SS, et al. <em>Eur J Cancer</em>. 2009;45:1807–14.
              </div>

            </Tab>
            <Tab title="Response Rates">
              <h2>
                5-Year Response Rates – All Patients (By Central Review)<sup>1</sup>
              </h2>
              <p>
                Patients were assessed for best overall response (complete response (CR), partial response (PR), stable disease, progressive disease or unknown; derived per RECIST version 1.1).
              </p>

              <div class="rr-top-boxes">
                <div class="left">

                  <img src="~@/assets/images/rr-icons@2x.png" width="243" alt="">
                  <p>
                    More than <strong>60%</strong> of patients had a partial or complete response with <span class="no-wrap"><span class="highlight">BRAFTOVI<sup>&reg;</sup>
                    (encorafenib) + MEKTOVI<sup>&reg;</sup> (binimetinib)</span></span>
                  </p>
                </div>

                <div class="right">
                  <h3 class="text-center" style="margin-top: 0px;">5-Year Response Rates<sup>1</sup></h3>
                  <img src="~@/assets/images/rr-graph1@2x.png" width="394" alt="">
                </div>
              </div>

              <div class="rr-bottom-boxes">
                <div class="rr-box">
                  <h3>Stable Disease</h3>
                  <p>
                    Includes patients with a status of non-CR or non-progressive disease
                  </p>
                  <div class="top">
                    <div class="title">BRAFTOVI + MEKTOVI</div>
                    <div class="value">54 patients (28.1%)</div>
                  </div>
                  <div class="bottom">
                    <div class="title">Vemurafenib</div>
                    <div class="value">77 patients (40.3%)</div>
                  </div>
                </div>
                <div class="rr-box">
                  <h3>Progressive Disease</h3>
                  <p>
                    Includes patients with best response of unknown or
                    no assessment
                  </p>
                  <div class="top">
                    <div class="title">BRAFTOVI + MEKTOVI</div>
                    <div class="value">15 patients (7.8%)</div>
                  </div>
                  <div class="bottom">
                    <div class="title">Vemurafenib</div>
                    <div class="value">36 patients (18.8%)</div>
                  </div>
                </div>
                <div class="rr-box">
                  <h3>Disease Control Rate</h3>
                  <p>
                    Patients with a best overall response of confirmed CR, PR or stable disease
                  </p>
                  <div class="top">
                    <div class="title">BRAFTOVI + MEKTOVI</div>
                    <div class="value">92.2% <span class="smaller">(95% CI, 87.4–95.6)</span></div>
                  </div>
                  <div class="bottom">
                    <div class="title">Vemurafenib</div>
                    <div class="value">81.2% <span class="smaller">(95% CI, 74.9–86.4)</span></div>
                  </div>
                </div>
                <div class="rr-box">
                  <h3>Median Duration
                    of Response</h3>
                  <p>
                    Time from date of first response to first progression or death due to melanoma
                  </p>
                  <div class="top">
                    <div class="title">BRAFTOVI + MEKTOVI</div>
                    <div class="value">18.6 months</div>
                  </div>
                  <div class="bottom">
                    <div class="title">Vemurafenib</div>
                    <div class="value">12.3 months</div>
                  </div>
                </div>
              </div>

              <div class="references top-border">
                CI, confidence interval; CR, complete response; HR, hazard ratio; ORR, overall response rate; PR, partial response; RECIST, Response Evaluation Criteria in Solid Tumours.
                <br>
                1. Pierre Fabre, Data on File: 01-2021-UK.
              </div>

            </Tab>
            <Tab title="Safety">
              <h2>5-Year Safety – All Patients<sup>1</sup></h2>
              <p>
                The safety profile over 5 years was consistent with previous observations with <span class="highlight">BRAFTOVI<sup>&reg;</sup>
                (encorafenib) + MEKTOVI<sup>&reg;</sup> (binimetinib)</span>.
              </p>

              <div class="safety-box">
                <div class="top">
                  <img src="~@/assets/images/exclamation@2x.png" width="60" alt="">
                  <span>AEs occurring in <span class="larger">&ge;30%</span> of patients:</span>
                </div>
                <div class="bottom flex flex-row flex-align-center">
                  <div>
                    Nausea <br>
                    (44.3%)
                  </div>
                  <div>
                    Diarrhoea <br>
                    (39.1%)
                  </div>
                  <div>
                    Vomiting <br>
                    (33.3%)
                  </div>
                  <div>
                    Fatigue <br>
                    (30.2%)
                  </div>
                  <div>
                    Arthralgia <br>
                    (30.2%)
                  </div>
                </div>
              </div>
              
              <h3 style="margin-top: 40px;">Grade 3/4 Adverse Events<sup>1</sup></h3>

              <img class="centered" src="~@/assets/images/safety-table1@2x.png" style="margin-top: 40px;" width="761" alt="">

              <p style="margin-top: 40px;">
                Abnormal laboratory values were observed in some patients:
              </p>
              
              <h3>
                Laboratory Values<sup>1</sup>
              </h3>

              <img class="centered" src="~@/assets/images/safety-table2@2x.png" width="760" alt="">


              <div class="safety-box-bottom">
                <p>
                  Evaluating patients with specific tests is recommended prior to, during, and after treatment with <span class="highlight no-wrap">BRAFTOVI
                + MEKTOVI</span>. Please consult the Summary of Product Characteristics for more information.<sup>2,3</sup>
                </p>
                <p>
                  Dose adjustments may be required in the management of some toxicities. Please consult the Summary of Product Characteristics for more information.<sup>2,3</sup>
                </p>

              </div>

              <div class="references top-border">
                AE, adverse event; ALT, alanine aminotransferase; CPK, creatine phosphokinase; GGT, gamma-glutamyltransferase.
                <br>
                1. Pierre Fabre, Data on File: 01-2021-UK. 2. BRAFTOVI<sup>&reg;</sup> (encorafenib) Summary of Product Characteristics. 3. MEKTOVI<sup>&reg;</sup> (binimetinib) Summary of Product Characteristics.
              </div>

              <div class="foot-bar">
                A therapy management guide is available upon request.
              </div>
            </Tab>
          </Tabs>
        </Modal>
      </div>

    </section>

</template>

<script>
import Modal from "../components/Modal";
import Tabs from "../components/tabs/Tabs";
import Tab from "../components/tabs/Tab";
import FullScreenOverlay from "../components/FullScreenOverlay";
export default {
  name: "Main",
  components: {FullScreenOverlay, Tab, Tabs, Modal},
  data: function () {
    return{
      isMainContentOpen : false
    }
  },
  methods:{
    selectHotspot: function (tabIndex) {
      this.isMainContentOpen = true;
      var app = this;
      this.$nextTick(function () {
        app.$refs.maincontent.openModal()
        app.$refs.maintabs.tabClicked(parseInt(tabIndex))
        document.querySelectorAll(".modal-inner").forEach(function (element) {
          element.scrollTop = 0;
        })
      })
    },
    tabUpdated: function (tabIndex) {
      this.$router.push({name: "home", params: {defaultTabIndex: tabIndex}}).catch(function () {})
    },
    onContentClosed: function () {
      this.isMainContentOpen = false;
      this.$router.push({name: "home"}).catch(function () {})
    },
    openOverlay: function (target_component) {
      this.$refs[target_component].openOverlay();
    }
  },
  mounted : function () {
    if(this.defaultTabIndex !== null){
      this.selectHotspot(this.defaultTabIndex)
    }

    //debugger;
    this.$nextTick(this.$root.calcInnerHeight);
    //this.$root.calcInnerHeight();
  },
  props:{
    defaultTabIndex:{
      default: null
    }
  },
  watch:{
    $route : function (){
      if(this.defaultTabIndex !== null){
        this.selectHotspot(this.defaultTabIndex)
      }else{
        this.isMainContentOpen = false;
      }

    }
  }
}
</script>

<style scoped>

</style>