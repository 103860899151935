<template>
  <div class="overlay-container" v-bind:class="{'open' : isOpen}">
    <div class="overlay-content">
      <div class="close" @click="closeOverlay"></div>
      <div class="wrap">
        <slot>

        </slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FullScreenOverlay",
  data: function () {
    return{
      isOpen : false
    }
  },
  methods: {
    openOverlay: function () {
      this.isOpen = true;
    },
    closeOverlay : function () {
      this.isOpen = false;
    }
  }
}
</script>

<style scoped type="text/scss">

</style>