<template>
  <section class="welcome-section">
    <img class="butterfly-one" src="~@/assets/images/butterflies2.gif" alt="">

    <div class="container">
      <div class="welcome-box">
<!--        <img class="butterfly-one" src="~@/assets/images/butterfly2_1.gif" alt="">-->
        <div class="welcome-content">
          <h2>
            Presenting <span>New</span> <br>
            5-Year Survival Data
          </h2>

          <router-link class="enter-button" :to="{name : 'home'}">
            Enter
          </router-link>

          <p class="sub-text">
            BRAFTOVI<sup>&reg;</sup> (encorafenib) in combination with MEKTOVI<sup>&reg;</sup> (binimetinib) is indicated for the treatment of adult patients with unresectable or metastatic melanoma with a BRAF V600 mutation<sup>1,2</sup>
          </p>

          <p class="references">
            1. BRAFTOVI<sup>&reg;</sup> (encorafenib) summary of product characteristics. 2. MEKTOVI<sup>&reg;</sup> (binimetinib) summary of product characteristics.
          </p>
        </div>

      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Welcome"
}
</script>

<style scoped>

</style>