import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'

import "./stylesheets/styles.scss"

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App),
  watch:{
    $route (){
      this.$nextTick(this.calcInnerHeight);
      //this.calcInnerHeight();
    }
  },
  methods: {
    calcInnerHeight: function () {
      //debugger;
      var header_height = document.querySelector("header").offsetHeight;
      var footer_height = document.querySelector("footer").offsetHeight;
      var window_height = window.innerHeight;

      var target_height = window_height - footer_height - header_height - 85 - 50;

      document.querySelectorAll(".modal-inner:not(.no-forced-height)").forEach(function (element) {
        element.style.maxHeight = target_height+"px";
      })

    }
  },
  mounted: function () {
    this.calcInnerHeight();
    window.addEventListener("resize", this.calcInnerHeight)
  }
}).$mount('#app')
