<template>
  <section class="modal" v-bind:class="{transparent : transparent}" v-show="isActive">
    <div class="modal-inner" v-bind:class="{'no-forced-height' : noAutoHeight}">
      <slot></slot>
    </div>
    <div class="close-button" @click="closeModal"></div>

  </section>
</template>

<script>
export default {
  name: "Modal",
  data: function () {
    return{
      isActive : false
    }
  },
  methods: {
    openModal: function () {
      this.isActive = true;
      this.$emit("modalopened");
    },
    closeModal : function () {
      this.isActive = false;
      this.$emit("modalclosed");
    },
    setIsOpen: function (isOpen) {
      this.isActive = isOpen;
      this.$emit("modal"+isOpen ? "opened" : "closed");
    }
  },
  mounted: function () {
    this.isActive = this.openedByDefault;
  },
  props: {
    openedByDefault: {
      type: Boolean,
      default: false
    },
    transparent: {
      type: Boolean,
      default: false
    },
    noAutoHeight: {
      type: Boolean,
      default: false
    }
  }

}
</script>

<style scoped>

</style>