<template>
  <div class="tabs">
    <div class="nav-wrapper">
      <ul class="tabs-nav">
        <li v-bind:class="{'active' : selectedTabIndex === index}" :key="tab.title" v-for="(tab, index) in this.tabs" @click="tabClicked(index)">{{tab.title}}</li>
      </ul>
    </div>
    <slot>

    </slot>
  </div>
</template>

<script>
export default {
  name: "Tabs",
  data: function () {
    return{
      tabs: [],
      selectedTabIndex : 0
    }
  },
  methods:  {
    tabClicked: function (index) {

      // loop over all the tabs
      this.tabs.forEach(function(tab, i) {
        tab.isActive = (index === i)
      })

       if(this.selectedTabIndex !== index){
        this.$emit("tabupdated",index)
      }

      this.selectedTabIndex = index;
    },
    getCurrentIndex : function () {
      return this.selectedTabIndex;
    }
  },
  created: function () {
    this.tabs = this.$children;
  },
  mounted: function () {
    this.tabClicked(0)
  }
}
</script>

<style scoped>

</style>