import Vue from 'vue'
import VueRouter from 'vue-router'
import Welcome from "../pages/Welcome";
import Main from "../pages/Main";


Vue.use(VueRouter)

const routes = [
  {
    path: '',
    name: 'welcome',
    component: Welcome
  },
  {
    path: "/home/:defaultTabIndex?",
    name : "home",
    component: Main,
    props: true
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
