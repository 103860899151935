<template>
  <div class="tab" v-show="isActive">
      <slot></slot>
  </div>
</template>

<script>
export default {
  name: "Tab",
  data: function () {
    return{
      isActive : false
    }
  },
  props: [
      "title"
  ]
}
</script>

<style scoped>

</style>